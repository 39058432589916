:root {
  --neon-text-color: #a3a3a3;
  --neon-border-color: #a3a3a3;
}
.main_head {
  position: relative;
  width: 100%;
  /* background-color: antiquewhite; */
  min-height: 100vh;
  overflow: hidden;
}

.main_head span {
  color: #facf39;
}

.main_head h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20vw;
  letter-spacing: 5vw;
  opacity: 0.75;
  font-weight: 600;
}

#head_top_heading {
  top: 100%;
}
#head_bottom_heading {
  top: 0%;
}
.top {
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  background-image: linear-gradient(90deg, #f9a553 5%, #facf39);
  z-index: 1000;
  overflow: hidden;
}
.bottom {
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(90deg, #f9a553 5%, #facf39);
  overflow: hidden;
}
.center {
  height: 100vh;
  width: 100%;
  /* background-color: rgb(0, 0, 0); */
  position: relative;
}

.empower{
  
  opacity: 0;
  position:absolute;
  top: 78%;
  right: -100%;
}
.box_content span {
  position: absolute;
  color: #facf39;
  font-size: 10vw;
  font-weight: 700;
  /* -webkit-box-reflect: below -60% linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25)); */
}
.header{
  opacity: 0;
}
.name_left {
  top: 50%;
  left: 21%;
  transform: translate(-55%, -50%);
  font-weight: 700;
  text-shadow: 15px 14px 3px rgba(0, 0, 0, 0);
}
.name_right {
  top: 50%;
  right: 21%;
  transform: translate(28%, -50%);
}
.box_content {
  width: 100%;
  height: 100%;
  opacity: 0;
  scale: 0.5;
  position: relative;
  top: 0;
}

.center .product_image {
  aspect-ratio: calc(437 / 480);
  position: absolute;
  top: 100%;
  width: 20%;
  max-height: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center .product_image img {
  width: 100%;
  height: 100%;
  object-fit:contain;
  object-position: center;
  filter: drop-shadow(-39px 29px 25px #000000);
}
.action_perform {
  position: absolute;
  /* border: 2px solid black; */
  color: #000000;
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100000000000000000000000000 !important;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 10%);
  animation: floating ease-in-out 1.5s infinite;
}

.action_perform p {
  animation: floating1 ease-in-out 1.5s infinite;
}

.mouse {
  height: 50px;
}
.center_main_content_left {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0%;
}

.content_center {
  position: relative;
}

.content_center h2 {
  position: absolute;
  color: white;
  font-weight: 700;
  font-size: 4vw;
  letter-spacing: 10px;
  margin-left: 0%;
  opacity: 0;
  top: 0em;
}
.content_center .para_first_center {
  position: absolute;
  color: #facf39;
  font-weight: 200;
  font-size: 2vw;
  letter-spacing: 5px;
  width: 70%;
  margin-left: 0%;
  opacity: 0;
  top: 13vh;
}
.content_center .para_second_center {
  position: absolute;
  color: white;
  font-weight: 400;
  font-size: 1.25vw;
  letter-spacing: 2.5px;
  margin-left: 0%;
  opacity: 0;
  top: 29vh;
}

.center_product_price {
  position: absolute;
  color: #facf39;
  font-size: 3vw;
  font-weight: 300;
  letter-spacing: 15px;
  margin-left: 0%;
  opacity: 0;
  top: 41vh;
}

.center_button {
  position: absolute;
  top: 51vh;
  margin-left: 10%;
  width: 100%;
  margin-left: 0%;
  opacity: 0;
}
.center_main_button {
  width: 15vw;
  height: 4.5vw;
  font-weight: 300;
  padding: 0%;
  font-size: 1.2vw;
  letter-spacing: 5px;
  cursor: pointer;
  transition: all ease 0.2s;
}

.center_button .buy_button {
  background-color: #facf39;
  border: 0px;
}
.center_button .info_button {
  background-color: transparent;
  color: #a3a3a3;
  border: 4px solid #a3a3a3;
  margin-left: 6%;
  animation: flicker 1.5s infinite alternate;
}

.center_button .buy_button:hover {
  background-color: #facf39;
}
.center_button .info_button:hover {
  background-color: #a3a3a351;
}

.info_button::-moz-selection {
  background-color: var(--neon-border-color);
  color: var(--neon-text-color);
}

.info_button::selection {
  background-color: var(--neon-border-color);
  color: var(--neon-text-color);
}

@keyframes fades {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes floating {
  0% {
    transform: translate(-50%, -25%) rotate(360deg);
  }
  50% {
    transform: translate(-50%, -8%) rotate(360deg);
  }
  100% {
    transform: translate(-50%, -25%) rotate(360deg);
  }
}
@keyframes floating1 {
  0% {
    transform: translate(0%, -10%) rotate(360deg);
  }
  50% {
    transform: translate(0%, 10%) rotate(360deg);
  }
  100% {
    transform: translate(0%, -10%) rotate(360deg);
  }
}

@keyframes flicker {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    text-shadow: -0.2rem -0.2rem 1rem #fff, 0.2rem 0.2rem 1rem #fff,
      0 0 2rem var(--neon-text-color), 0 0 4rem var(--neon-text-color),
      0 0 6rem var(--neon-text-color), 0 0 8rem var(--neon-text-color),
      0 0 10rem var(--neon-text-color);

    box-shadow: 0 0 0.5rem #eae9e9, inset 0 0 0.5rem #fff,
      0 0 2rem var(--neon-border-color), inset 0 0 2rem var(--neon-border-color),
      0 0 4rem var(--neon-border-color), inset 0 0 4rem var(--neon-border-color);
  }

  20%,
  24%,
  55% {
    text-shadow: none;
    box-shadow: none;
  }
}

@media (max-width:1000px) {
  .main_head{
    height: 100%;
  }
  .center{
    height: 100%;
  }
  .center .product_image {
    width: 20vmax;
  }
  .name_left {
       font-size: 2vmax;
       transform: translate(-50%, -50%);
      }
      .name_right {
    font-size: 2vmax;
    transform: translate(20%, -50%);
  }
  .center_main_content_left {
    position: absolute;
    z-index: -1;
    
    /* height: calc(100vh - 100px); */
    width: 100%;
    /* top:90px; */
  }
  
  .content_center {
    position: relative;
    width: 85vw;
    margin: 0 auto;
    padding-top: 10vmax;
    height: 100%;
    display: flex;
    text-align: left;
  }
  
  .content_center h2 {
    position: absolute;
    color: white;
    font-weight: 600;
    font-size: 3.5vmax;
    letter-spacing:5px;
    padding-left: 10px;
    width: 100%;
    margin-left: 0%;
    opacity: 0;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
   .content_center h2 >div{
     width: fit-content !important;
     margin: 0 auto;
  }

  .content_center h2 div div div{
    opacity: 0.4;
  }
  ._item-container-mainPage{
    width: 100%;
    top:60vh;
    position: absolute;
    opacity: 0;
    height: 20%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start ;

  }
  .content_center .para_first_center {
    position: static;
    color: #10948c;
    font-weight: 200;
    font-size: 3.5vmax;
    margin:1vmax 0;
    letter-spacing: 5px;
    width: 100%;
    padding-left: 10px;
    opacity: 0;
  }
  .content_center .para_second_center {
    position: static;
    color: white;
    padding-left: 10px;
    font-weight: 400;
    font-size: 2vmax;
    margin:1vmax 0;
    letter-spacing: 2.5px;
    margin-left: 0%;
    opacity: 0;
    top: 50vmax;
  }
  .center_product_price {
    position: static;
    color: #10948c;
    font-size: 3vmax;
    font-weight: 300;
    letter-spacing: 15px;
    margin:1vmax 0;
    margin-left: 0%;
    opacity: 0;
    padding-left: 10px;
    top: 70vmax;
    justify-items: end;
  }

  .center_button {
    position: static;
    display: flex;
    top: 80vmax;
    margin-left: 10%;
    width: 100%;
    margin:1vmax 0;
    margin-left: 0%;
    padding-left: 10px;
    opacity: 0;
  }
  .center_main_button {
    width: 15vmax;
    height: 4.5vmax;
    font-weight: 500;
    font-size: 1.6vmax;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all ease 0.2s;
  }
  
  .center_button .buy_button {
    background-color: #10948c;
    border: 0px;
  }
  .center_button .info_button {
    background-color: transparent;
    color: #a3a3a3;
    border: 4px solid #a3a3a3;
    margin-left: 3vmax;
    animation: none;
  }
  
}
@media (max-height:500px) {
  .center{
    height:50vh;
  }
  .center .product_image {
    width: 20vmax;
  }
  .name_left {
       font-size: 2vmax;
      }
      .name_right {
    font-size: 2vmax;

  }
  .center_main_content_left {
    position: absolute;
    z-index: -1;
    /* height: calc(100vh - 100px); */
    width: 100%;
    /* top:90px; */
  }
  
  .content_center {
    position: relative;
    width: 85vw;
    margin: 0 auto;
    padding-top: 10vmax;
    height: 100%;
    display: flex;
    text-align: left;
  }
  
  .content_center h2 {
    position: absolute;
    color: white;
    font-weight: 600;
    font-size:4vmax;
    letter-spacing:5px;
    padding-left: 10px;
    padding-left: 20%;
    width: 100%;
    margin-left: 0%;
    opacity: 0;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
  .content_center h2 div{
    opacity: 0.4;
  }
  ._item-container-mainPage{
    width: 100%;
    top:60vh;
    position: absolute;
    opacity: 0;
    height: 20%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start ;

  }
  .content_center .para_first_center {
    position: static;
    color: #10948c;
    font-weight: 200;
    font-size: 2.5vmax;
    margin:0.5vmax 0;
    letter-spacing: 5px;
    width: 100%;
    padding-left: 10px;
    opacity: 0;
  }
  .content_center .para_second_center {
    position: static;
    color: white;
    padding-left: 10px;
    font-weight: 400;
    font-size: 1.5vmax;
    margin:0.5vmax 0;
    letter-spacing: 2.5px;
    margin-left: 0%;
    opacity: 0;
    top: 50vmax;
  }
  .center_product_price {
    position: static;
    color: #10948c;
    font-size: 2vmax;
    font-weight: 300;
    letter-spacing: 5px;
    margin:0.5vmax 0;
    margin-left: 0%;
    opacity: 0;
    padding-left: 10px;
    top: 70vmax;
    justify-items: end;
  }

  .center_button {
    position: static;
    display: flex;
    top: 80vmax;
    margin-left: 10%;
    width: 100%;
    margin:0.5vmax 0;
    margin-left: 0%;
    padding-left: 10px;
    opacity: 0;
  }
  .center_main_button {
    width: 15vmax;
    height: 4.5vmax;
    font-weight: 500;
    font-size: 1.6vmax;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all ease 0.2s;
  }
  
  .center_button .buy_button {
    background-color: #10948c;
    border: 0px;
  }
  .center_button .info_button {
    background-color: transparent;
    color: #a3a3a3;
    border: 4px solid #a3a3a3;
    margin-left: 3vmax;
    animation: none;
  }
  
}